import { graphql, useStaticQuery } from 'gatsby';

const useLatestPostsEs = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicBlog(
        filter: { lang: { eq: "es-do" } },
        limit: 3,
        sort: { fields: first_publication_date, order: DESC }) 
        { edges {
          node {
            uid
            tags
            data {
              date
              title {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                url
                alt
              }
              content {
                text
              }
            }
          }
        }
      }
    }
  `
)

  return data.allPrismicBlog.edges.map(({ node }) => ({
    ...node.data.image.localFile.childImageSharp,
    slug: node.uid,
    tags: node.tags,
    date: node.data.date,
    title: node.data.title.text,
    content: node.data.content.text,
    image: node.data.image.url,
    alt: node.data.image.alt,
  }));
}

export default useLatestPostsEs;