import { useState, useEffect } from 'react';

export default (screenSize) => {
  const [ state, setState ] = useState(false);

  function trackWindowWith() {
   return window.screen.width <= screenSize ? setState(true) : setState(false);
  }

  useEffect(() => {
    window.addEventListener('resize', trackWindowWith);
    window.addEventListener('load', trackWindowWith);
    return () => {
      window.removeEventListener('resize', trackWindowWith);
      window.removeEventListener('load', trackWindowWith);
    }
  })

  return [ state ];
}