import React, { useContext } from 'react';
import Image from 'gatsby-image';
import { ThemeContext } from '../../../../providers/ThemeProvider';
import './CardProject.scss';

export const CardProject = (props) => {
  const theme = useContext(ThemeContext);
  const selectedTheme = theme === 'dark' ? 'lighter-dark-theme' : 'light-theme';
  return ( 
    <a href={props.link} 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ color: 'black', textDecoration: 'none' }}
    >
      <article className={`${props.containerClassName} ${selectedTheme}`}>
        <h1 className={props.titleClassName}>{props.title}</h1>
        <div>
          <Image
            fluid={props.image}
            alt={props.alt}
            className={`${props.imageClassName} image`}
          />
        </div>
        <p className={props.paragraphClassName}>
          {props.paragraph}
        </p>
      </article>
    </a>
  );
}