import React, { useContext } from 'react';
import { Link } from 'gatsby-plugin-intl';
import Image from 'gatsby-image';
import { ThemeContext } from '../../../../providers/ThemeProvider';
import { Badge } from '../../../Common';
import windowsWithTracker from '../../../../hooks/useWindowsWith';
import './CardArticles.scss';

export const CardArticle = (props) => {
  const theme = useContext(ThemeContext);
  const seletctedTheme = theme === 'dark' ? 'lighter-dark-theme' : 'light-theme';
  const [ isWindowsOverThatWith ] = windowsWithTracker(700);
  return ( 
    <Link to={`/blog/${props.link}`} >
      <div className={`card-articles flex md:flex-col mb-8 ${seletctedTheme}`}>
        { props.image ?
          <Image
            fluid={props.image}
            alt={props.alt}
            className="rounded-lg w-1/4 md:w-full"
          /> 
            :
          <img 
            className="w-5/12 h-auto md:w-6/12 lg:w-full image rounded-lg"
            src={props.imageUrl} 
            alt={props.alt}
          />
        }
        <article className="p-4 md:p-6 flex flex-col justify-around w-9/12 md:w-full">
          <h1 className="text-3xl  md:text-5xl font-bold">
            { props.title.length > 18 ? 
              `${props.title.substring(0,18)}...` : 
              props.title
            }
          </h1>
          <p className="text-justify three-dots text-2xl md:text-3xl">
          { isWindowsOverThatWith ? 
            props.content.substring(0, 50) :
            props.content.substring(0, 200)
          }
          </p>
          <div className="mt-4 md:mt-6">
            {props.badges.length !== 0 ? 
                <div>
                  <Badge
                    className={props.badges[2]}
                    text={props.badges[0]}
                  />
                  <Badge
                    className={`${props.badges[3]} ml-8`}
                    text={props.badges[1]}
                  />
                </div>
                  : 
                <Badge
                  className="bg-black"
                  text="no tag"
                />
            }
          </div>
        </article>
      </div>
    </Link>
  );
}