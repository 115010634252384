import React, { useContext } from 'react';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ThemeContext } from '../../../providers/ThemeProvider';
import useAllInconsImages from '../../../graphql/queries/useAllIconsImages';
import './Skills.scss';

export const Skills = (props) => {
  const images = useAllInconsImages();
  const theme  = useContext(ThemeContext);
  const selectedTheme = theme === 'dark' ? 'lighter-dark-theme' : 'darker-light-theme';
  return ( 
    <section className={` ${selectedTheme} skills text-center mb-6 py-8 px-4`}>
      <h1 className="text-6xl mt-12"><FormattedMessage id="skills" /></h1>
      <div className="images-container flex flex-col items-center md:flex-row md:justify-center">
        {images.map((image, index) => (
          index < 3 ?
            <Image
              key={image.id}
              fluid={image.fluid}
              alt={Image.alt}
              className="icon my-10 md:mb-0"
            />
              :
            ''
        ))}
      </div>
      <div className="images-container flex flex-col items-center md:flex-row md:justify-center">
        {images.map((image, index) => (
          index > 2 ?
            <Image
              key={image.id}
              fluid={image.fluid}
              alt={Image.alt}
              className="icon my-10 md:mb-0"
            />
              :
            ''
        ))}
      </div>
    </section>
  );
}
