import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { CardArticle } from './CardArticle/CardArticles';
import useLatestPostsEn from '../../../graphql/queries/useLatestPostsEn';
import useLatestPostsEs from '../../../graphql/queries/useLatestPostsEs';

export const LatestArticles = (props) => {
  const intl = useIntl();
  const postsEn = useLatestPostsEn();
  const postsEs = useLatestPostsEs();

  function selectLanguageLatestArt() {
    if (intl.locale === 'en') {
      return postsEn.map((post, index) => (
        <CardArticle
          key={post.slug}
          title={post.title}
          link={post.slug}
          image={post.fluid} 
          imageUrl={post.image}
          content={post.content}
          badges={post.tags}
        />
      ))
    }
    return postsEs.map((post, index) => (
      <CardArticle
        key={post.slug}
        title={post.title}
        link={post.slug}
        image={post.fluid} 
        imageUrl={post.image}
        content={post.content}
        badges={post.tags}
      />
    ))
  }


  return ( 
    <section className="py-4 px-8">
      <h1 className="text-6xl text-center mb-12">
        {<FormattedMessage id="latestArticles" />}
      </h1>
      <div className="md:flex md:justify-around md:flex-wrap">
        {selectLanguageLatestArt()}
      </div>
    </section>
  );
}
