import React, { useContext } from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Button } from '../../Common';
import { ThemeContext } from '../../../providers/ThemeProvider';
import './Description.scss';

export const Description = (props) => {
  const theme = useContext(ThemeContext);
  return ( 
    <section className="description">
      <h1 className={`${theme === 'dark' ? 'text-white' : 'text-gray-900'}  text-5xl md:text-6xl mb-6`}>
      {<FormattedMessage id="user" />}
      </h1>
      <p className={`text-3xl mb-8 ${theme === 'dark' ? 'transparent-text' : 'text-gray-700'} `}>
        {<FormattedMessage id="description" />}
      </p>
      <a href="https://drive.google.com/open?id=1PkgdPsjU0oW36zbIDxKX6-v9A-pphDsk" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ color: 'black', textDecoration: 'none', outline: 'none' }}
      >
        <Button
          className="button-resume md:w-auto md:mr-6 w-full p-4 text-3xl mb-8"
          text={<FormattedMessage id="resume" />}
        />
      </a>
      <Link to="/about">
        <Button
          className="button-about-me md:w-auto w-full p-4 text-white text-3xl"
          text={<FormattedMessage id="me" />}
        />
      </Link>
    </section>
  );
}