import { graphql, useStaticQuery } from 'gatsby';

const useAllInconsImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/icon/" } }) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return data.allFile.edges.map(({ node }) => ({
    ...node.childImageSharp,
    id: node.id,
    alt: node.name,
  }));
}

export default useAllInconsImages;