
import React, { useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Layout } from '../components/Common/';
import { Description, Skills, Projects, LatestArticles } from '../components/Landing';
import { SetLocationContext } from '../providers/LocationProvider';

const Home = (props) => {
  const { formatMessage } = useIntl();
  const setLocation  = useContext(SetLocationContext);
  setLocation(formatMessage({ id: 'navLink1' }));
  return (
    <Layout>
      <Description />
      <Skills />
      <Projects />
      <LatestArticles />
    </Layout>
  );
}
 
export default Home;
