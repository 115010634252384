import { graphql, useStaticQuery } from 'gatsby';

const useProjetcsEs = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicProject( 
        filter: { lang: { eq: "es-do" } },
        sort: { fields: first_publication_date, order: ASC  } ) 
      {
        edges {
          node {
            uid
            data {
              link {
                url
              }
              title {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                url
                alt
              }
              content {
                text
              }
            }
          }
        }
      }
    }
  `
)

  return data.allPrismicProject.edges.map(({ node }) => ({
    ...node.data.image.localFile.childImageSharp,
    id: node.uid,
    link: node.data.link.url,
    title: node.data.title.text,
    content: node.data.content.text,
    image: node.data.image.url,
    alt: node.data.image.alt,
  }));
}

export default useProjetcsEs;