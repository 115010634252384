import React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { CardProject } from './CardProject/CardProject';
import useProjectsEn from '../../../graphql/queries/useProjectsEn';
import useProjectsEs from '../../../graphql/queries/useProjectsEs';


export const Projects = (props) => {
  const intl = useIntl();
  const projectsEn = useProjectsEn();
  const projectsEs = useProjectsEs();

  function selectLanguageProject() {
    if (intl.locale === 'en') {
      return projectsEn.map(project => (
        <CardProject
          key={project.id}
          containerClassName="card-project mb-12 rounded"
          title={project.title}
          titleClassName="text-4xl p-2 font-bold"
          link={project.link}
          image={project.fluid}
          alt={project.alt}
          imageClassName="w-full"
          paragraph={project.content}
          paragraphClassName="paragraph text-4xl p-6 text-justify"
        />
      ))
    }
    return projectsEs.map(project => (
      <CardProject
        key={project.id}
        containerClassName="card-project mb-12 rounded"
        title={project.title}
        titleClassName="text-4xl p-2 font-bold"
        link={project.link}
        image={project.fluid}
        alt={project.alt}
        imageClassName="w-full"
        paragraph={project.content}
        paragraphClassName="paragraph text-4xl p-6 text-justify"
      />
    ))
  }

  return ( 
    <section className="projects  py-4 px-8 text-center">
      <h1 className="text-6xl mt-4 mb-12">{<FormattedMessage id="projects" />}</h1>
      <div className="md:flex md:flex-row md:justify-around md:flex-wrap">
        {selectLanguageProject()}
      </div>
    </section>
  );
}